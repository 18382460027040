import portfolioImages from 'components/Portfolio/portfolioContent';

const [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
] = portfolioImages;

const portfolio = {
  title: "Деякі з наших об'єктів",

  portfolioContent: [
    {
      title: 'Приватний котедж в с.Княжичі Київської області',
      img: img1,
      id: 'img1',
      alt: 'house in Kniagychi',
    },
    {
      title: 'Автостоянка на 400 машиномісць, м.Львів',
      img: img2,
      id: 'img2',
      alt: 'parking for 400 autos in Liviv',
    },
    {
      title: 'Багатоповерховий житловий будинок, м.Львів',
      img: img3,
      id: 'img3',
      alt: 'appartmens house in Lviv ',
    },
    {
      title:
        'Реконструкція нежитлових приміщень під ресторан «Червона рута» на вул. Хуторівка, 28 в м. Львові',
      img: img4,
      id: 'img4',
      alt: 'Chervona Ruta ',
    },
    {
      title:
        'Реконструкція нежитлових приміщень під адміністративно-технічний комплекс ОФ ПрАТ «Київстар» в м. Одеса ',
      img: img5,
      id: 'img5',
      alt: 'Data-center Kyivstar in Odesa',
    },
    {
      title:
        'Стоянка вантажних автомобілів, СТО, офісно-складські приміщення у с.  Солонка Львівської області ',
      img: img6,
      id: 'img6',
      alt: 'Data-center Kyivstar in Odesa',
    },
    {
      title:
        'Реконструкція «Євроготелю» за рахунок  надбудови поверху по вул. Тершаковців, 6а у м. Львові',
      img: img7,
      id: 'img7',
      alt: 'Eurohotel reconstruction in Lviv',
    },
    {
      title:
        'Канатно-крісельна дорога в с.Тисовець Сколівського району Львівської області',
      img: img8,
      id: 'img8',
      alt: 'cableway in Tysovetch ',
    },
    {
      title: 'Котедж в с.Підбірці Львівської області',
      img: img9,
      id: 'img9',
      alt: 'House in Pidbirtci ',
    },
    {
      title:
        'Проектна   пропозиція будівництва лікувально-відпочинкового комплексу на 180 місць у м. Східниця',
      img: img10,
      id: 'img10',
      alt: 'Therapeutic and health-improving for 180 places  Proposal in Shidnyca',
    },
    {
      title:
        'Реконструкція будівлі під адміністративний центр в м.Надвірна Івано-Франківської області',
      img: img11,
      id: 'img11',
      alt: 'building for the administrative center of the nature reserve "Gorgany"',
    },
    {
      title:
        'Нове будівництво візит-центру в с. Великий Березний Закарпатської області ',
      img: img12,
      id: 'img12',
      alt: 'visit center of the Uzhan National Park in the village of Velyky Berezny',
    },
  ],
};
export default portfolio;
