const contactUs = {
  title: 'Contact us',
  email: 'z1.yupin@gmail.com ',
  // telCEO: 'Director (Telegram | WhatsApp | Signal) : +380 97 001 75 44',
  // telCEO: 'Director (Telegram | WhatsApp | Signal) : +380 97 001 75 44',
  telCEO: {
    title: 'Director',
    tel: ' +380 97 001 75 44',
    // signal: {
    //   iconLink: '#icon-signal',
    //   link: 'https://signal.group/#CjQKIHQjU6qGEqT2PAEbyVS-fTtUQBjh3-qEUuPjxKxBoix9EhAmzjmm5wehEMql848R40mn',
    // },
    // telegram: {
    //   iconLink: '#icon-telegram',
    //   link: ' https://t.me/+380970017544',
    // },
    // whatsapp: {
    //   iconLink: '#icon-whatsapp',
    //   link: '',
    // },
  },
  telDD:'Vice Director: +380 67 819 02 85 ' ,
  adr: '79007 м.Lviv, Kleparivska St,11-a/2',
};
export default contactUs;
