const team = {
  teamTitle: 'About Us',
  subhead: '',
  companyName: 'Retro Project',
  part1:
    'is a creative team of professionals who know their business well and have already established themselves on the territory of Ukraine as a modern and promising consulting design and construction firm.',
  part2:
    'The company has in its staff highly qualified specialists with significant experience in the design of public, civil, industrial, sacred buildings and structures, engineering and technical personnel and workers of the necessary professions and qualifications who have knowledge and experience in the field of construction.',
  subtitle2: 'Company structure',
  directionsListTitle: 'The company includes project divisions:',
  directionsList: [
    {
      title: 'Architectural',
      content:
        ' - development of GP (master plan) and AR (architectural solutions)',
    },
    {
      title: 'Construction and design',
      content:
        ' - development of design calculations and drawings by section: RS (reinforced concrete structures), MS (metal structures), WS (wooden structures)',
    },
    {
      title: 'Sanitary and technical',
      content:
        ' - development of technical calculations and solutions for sections of the project: OV (heating, ventilation and air conditioning), VC (water supply and sewerage), TS (heat supply and gas supply)',
    },
    {
      title: 'Electrical engineering',
      content:
        ' - development of documentation by sections: EP/EO (power supply, electric lighting), AT (automation), SZ (communication systems)',
    },
    {
      title: 'Estimated',
      content: ' - development of estimate documentation (K)',
    },
  ],
  additionalDirections: {
    comment: 'And also ',
    title: 'Department of developer development and construction',
  },
  specialDirectionsTitle:
    '"Retro Proekt" LLC also develops specialized design sections: ',
  specialDirection: [
    '• technological section ',
    ' • examination and assessment of the technical condition of buildings and structures ',
    '• environmental impact assessment',
    '• design of construction organization',
    '•	Occupational Health',
    '• protection against noise exposure',
    '• consultations in the field of construction / Development of recommendations for the safe operation of buildings and structures',
  ],
};
export default team;
